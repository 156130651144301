export const Countdown = (function() {
  "use strict";

  const $days = document.querySelector(".js-countdown-days");
  const $daysDisplay = document.querySelector(".js-countdown-display-days");
  let daysVal = 0;

  const $hours = document.querySelector(".js-countdown-hours");
  const $hoursDisplay = document.querySelector(".js-countdown-display-hours");
  let hoursVal = 0;

  const $mins = document.querySelector(".js-countdown-mins");
  const $minsDisplay = document.querySelector(".js-countdown-display-mins");
  let minsVal = 0;

  const $seconds = document.querySelector(".js-countdown-seconds");
  const $secondsDisplay = document.querySelector(
    ".js-countdown-display-seconds"
  );
  let secondsVal = 0;

  const radius = $days.r.baseVal.value;
  const circumference = radius * 2 * Math.PI;

  /* =================== private methods ================= */
  function initCountdown() {
    $days.style.strokeDashoffset = "0";
    $days.style.strokeDasharray = `${circumference} ${circumference}`;

    $hours.style.strokeDashoffset = "0";
    $hours.style.strokeDasharray = `${circumference} ${circumference}`;

    $mins.style.strokeDashoffset = "0";
    $mins.style.strokeDasharray = `${circumference} ${circumference}`;

    $seconds.style.strokeDashoffset = "0";
    $seconds.style.strokeDasharray = `${circumference} ${circumference}`;

    initDays();
    initHours();
    initMins();
    initSeconds();
  }

  function initDays() {
    // calculated from 20. April 2019 to 4. June 2019 (Day 155) --> 45 days
    const today = new Date();
    const todaysNumber = Math.ceil(
      (today - new Date(today.getFullYear(), 0, 1)) / 86400000
    );

    const currentDays = 155 - todaysNumber;
    daysVal = currentDays * 2.2222222222;
    // daysVal = currentDays * 1.2987012987;

    setProgress(daysVal, "days");
    $daysDisplay.innerHTML = currentDays;

    window.setInterval(progressDays, 60000);
  }

  function initHours() {
    const currentHours = new Date().getHours();
    hoursVal = currentHours * 4.166666666666667;

    setProgress(hoursVal, "hours");
    $hoursDisplay.innerHTML = 24 - currentHours;

    window.setInterval(progressHours, 30000);
  }

  function initMins() {
    const currentMins = new Date().getMinutes();
    minsVal = currentMins * 1.6666666667;

    setProgress(minsVal, "mins");
    $minsDisplay.innerHTML = 60 - currentMins;

    window.setInterval(progressMins, 1000);
  }

  function initSeconds() {
    const currentSeconds = new Date().getSeconds();
    secondsVal = currentSeconds * 1.6666666667;

    setProgress(secondsVal, "seconds");
    $secondsDisplay.innerHTML = 60 - currentSeconds;

    window.setInterval(progressSeconds, 1000);
  }

  function setProgress(percent, type) {
    let offset;

    if (type == "days") {
      offset = circumference - ((100 - percent) / 100) * circumference;
    } else {
      offset = circumference - (percent / 100) * circumference;
    }

    switch (type) {
      case "seconds":
        $seconds.style.strokeDashoffset = offset;
        break;
      case "mins":
        $mins.style.strokeDashoffset = offset;
        break;
      case "hours":
        $hours.style.strokeDashoffset = offset;
        break;
      case "days":
        $days.style.strokeDashoffset = offset;
        break;
    }
  }

  function progressDays() {
    const today = new Date();
    const todaysNumber = Math.ceil(
      (today - new Date(today.getFullYear(), 0, 1)) / 86400000
    );

    const currentDays = 156 - todaysNumber;
    // daysVal = currentDays * 2.2222222222;
    daysVal = currentDays * 1.2987012987;

    setProgress(daysVal, "days");
    $daysDisplay.innerHTML = currentDays;
  }

  function progressHours() {
    const currentHours = new Date().getHours();
    hoursVal = currentHours * 4.166666666666667;

    setProgress(hoursVal, "hours");
    $hoursDisplay.innerHTML = 24 - currentHours;
  }

  function progressMins() {
    minsVal = minsVal + 0.027777777777778;

    setProgress(minsVal, "mins");
    $minsDisplay.innerHTML = 60 - new Date().getMinutes();
  }

  function progressSeconds() {
    secondsVal = secondsVal + 1.6666666667;

    setProgress(secondsVal, "seconds");
    $secondsDisplay.innerHTML = 60 - new Date().getSeconds();
  }

  /* =================== public methods ================== */

  // main init method
  function Init() {
    if (new Date().getTime() < new Date("June 4, 2019 23:59:59").getTime()) {
      initCountdown();
    }
  }

  /* =============== export public methods =============== */
  return {
    Init: Init
  };
})();
