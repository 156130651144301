export const Expander = (function() {
  "use strict";

  /* =================== private methods ================= */
  function InitListener() {
    const expanderEls = document.querySelectorAll(".js-expander");

    for (const el of expanderEls) {
      el.addEventListener("click", function(event) {
        if (
          event.target.parentNode
            .querySelector(".js-expander-text")
            .classList.contains("expanded")
        ) {
          // type close
          event.target.parentNode
            .querySelector(".js-expander-text")
            .classList.remove("expanded");
          event.target.querySelector(".js-expander-less").classList.add("hide");
          event.target
            .querySelector(".js-expander-more")
            .classList.remove("hide");
        } else {
          // type open
          event.target.parentNode
            .querySelector(".js-expander-text")
            .classList.add("expanded");
          event.target
            .querySelector(".js-expander-less")
            .classList.remove("hide");
          event.target.querySelector(".js-expander-more").classList.add("hide");
        }
      });
    }
  }

  /* =================== public methods ================== */

  // main init method
  function Init() {
    InitListener();
  }

  /* =============== export public methods =============== */
  return {
    Init: Init
  };
})();
