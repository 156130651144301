export const SmoothScroll = (function() {
  "use strict";

  /* =================== private methods ================= */
  function InitListener() {
    const buttons = document.querySelectorAll(".js-smooth-scroll");

    for (const button of buttons) {
      button.addEventListener("click", function(event) {
        const posTop = document.querySelector(event.target.getAttribute("href"))
          .offsetTop;

        window.scroll({
          behavior: "smooth",
          top: posTop,
          left: 0
        });
      });
    }
  }

  /* =================== public methods ================== */

  // main init method
  function Init() {
    InitListener();
  }

  /* =============== export public methods =============== */
  return {
    Init: Init
  };
})();
