export const Form = (function() {
  "use strict";

  /* =================== private methods ================= */
  function FormListener() {
    document
      .getElementsByClassName("js-form")[0]
      .addEventListener("submit", function(e) {
        e.preventDefault();

        const $form = document.getElementsByClassName("js-form")[0];
        const $formResult = document.getElementsByClassName(
          "js-form-result"
        )[0];
        const $formLoading = document.getElementsByClassName(
          "js-form-loading"
        )[0];

        $form.classList.add("submitted");
        $formLoading.classList.add("loading");

        if (e.target[1].value.startsWith("jkaiserrr123")) {
          $formLoading.classList.remove("loading");
          $formResult.classList.add("finished", "already-submitted");
          return;
        }

        const param = `email=${e.target[1].value}&answer=${e.target[0].value}`;
        const xhr = new XMLHttpRequest();

        xhr.open("POST", "/backend/submission.php", true);

        xhr.setRequestHeader(
          "Authorization",
          "Basic " + btoa("*MCfVgtUyoahxP:E#M9yjNMQof0j29$nMOuSnrG")
        );

        xhr.setRequestHeader(
          "Content-type",
          "application/x-www-form-urlencoded"
        );
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        xhr.onload = function() {
          let response;
          try {
            response = JSON.parse(this.response);
          } catch {
            // error!
            $formLoading.classList.remove("loading");
            $formResult.classList.add("finished", "error");
            return;
          }

          setTimeout(function() {
            if (typeof response.answer == "undefined") {
              try {
                if (response.message == "Already submitted!") {
                  // error! already submitted with this email
                  $formLoading.classList.remove("loading");
                  $formResult.classList.add("finished", "already-submitted");
                }
              } catch (e) {
                // error!
                $formLoading.classList.remove("loading");
                $formResult.classList.add("finished", "error");
              }
            } else {
              // success!
              $formLoading.classList.remove("loading");
              $formResult.classList.add("finished", "success");
            }
          }, 1500);
        };

        xhr.send(param);
      });
  }

  /* =================== public methods ================== */

  // main init method
  function Init() {
    if (new Date().getTime() < new Date("June 5, 2019 23:59:59").getTime()) {
      FormListener();
    } else {
      const $form = document.getElementsByClassName("js-form")[0];
      const $formResult = document.getElementsByClassName("js-form-result")[0];

      $form.classList.add("submitted");
      $formResult.classList.add("finished", "game-over");
    }
  }

  /* =============== export public methods =============== */
  return {
    Init: Init
  };
})();
