import { Countdown } from "./modules/module-countdown";
import { SmoothScroll } from "./modules/module-smooth-scroll";
import { Expander } from "./modules/module-expander";
import { Form } from "./modules/module-form";
import { PolyfillSmoothScroll } from "./modules/polyfill-smooth-scroll";

//modules
(function() {
  // kick off the polyfill!
  Countdown.Init();
  SmoothScroll.Init();
  Expander.Init();
  Form.Init();

  PolyfillSmoothScroll.Init();
})();

//Require Sass
require("../sass/main.scss");
